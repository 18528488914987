







































































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "alt-icon" })
export default class AltIcon extends Vue {
    @Prop({ type: String })
    private icon!: string;

    private click(): void {
        this.$emit("click");
    }
}
